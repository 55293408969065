import React from 'react';
import OnboardingTour from './views/OnboardingTour'
import { useRollbar } from 'src/providers/RollbarProvider';

const Onbording = () => {
  const rollbar = useRollbar()
  rollbar?.info('Visited onboarding. Test rollbar message');
  return (<OnboardingTour />)
}

export default Onbording
